//==  THIS SCRIPT MANAGES THE POSITION OF THE FOOTER, DEPENDING ON THE WINDOW AND CONTENT HEIGHT.
$(document).ready(function () {

  $(window).resize(setFooterPosition);
  $('#main-header').resize(setFooterPosition);
  $('#main-content').resize(setFooterPosition);
  $('#main-footer').resize(setFooterPosition);

  // This function must be called after all other scripts, to make sure all dynamic elements (like collapsibles) are finished redering.
  handleCollapseResize();
});


function handleCollapseResize() {
  $('.collapse').on('hidden.bs.collapse', function () {
    setFooterPosition();
  });
  $('.collapse').on('shown.bs.collapse', function () {
    setFooterPosition();
  });

  setFooterPosition();
}

function setFooterPosition() {
  var headerHeight = $('#main-header').innerHeight();
  var contentHeight = $('#main-content').innerHeight();
  var footerHeight = $('#main-footer').innerHeight();
  var windowHeight = $(window).height();

  if ((headerHeight + contentHeight + footerHeight) > windowHeight) {
    $('#main-footer').removeClass('fixed');
  }
  else {
    $('#main-footer').addClass('fixed');
  }
}
